<template>
    <div>
        <b-container>
            <section class="cookies">
                <h2 class="main-title">Cookie policy</h2>
                <h4 class="custom-subtitle">What are Cookies?</h4>
                <p class="custom-text">A cookie is a small file and holds a certain amount of data, which our website can send to your browser. It may then be stored on your computer's hard drive and can be accessed by our web server. This cookie data can then be retrieved and can allow us to customise our web pages and services accordingly. It is important to clarify that cookies do not collect any personal data stored on your hard drive or computer.</p>
                <h4 class="custom-subtitle">How we use Cookies?</h4>
                <p class="custom-text">We use cookies to identify the data about our site traffic and interaction so that we can personalize the content that you see on our Website and offer better site experiences. We may make agreements with third-party service providers to assist us in better understanding our site visitors.</p>
                <h4 class="custom-subtitle">What Cookies do we use?</h4>
                <p class="custom-text">Necessary Cookies - these cookies do not identify you as an individual, <br>
                Performance Cookies - these cookies do not identify you as an individual,<br>
                Functionality Cookies - the information these cookies collect may include personal data that you have disclosed,<br>
                Targeting or Advertising Cookies - most types of these cookies track consumers via their Device ID or IP address therefore they may collect personal data,<br>
                Third-Party Cookies - based on the type of cookies used by the relevant third-party, the information these cookies collect may include personal data.
                </p>
                <h4 class="custom-subtitle">How can I manage Cookie settings?</h4>
                <p class="custom-text">Cookies can be controlled by your web browser settings. Whether our cookies are used will depend on your browser settings, so you are in control. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, check your current browser settings. Please be aware that by blocking all cookies you may not be able to access or use all the features of our website and you will not receive a personalised service.
                </p>
                <h4 class="custom-subtitle">How long do we keep information that we collect from Cookies?</h4>
                <p class="custom-text">We only keep information for as long as is necessary for the purpose for which it was collected (subject to any legal requirements). Once it is no longer necessary, we will either delete the data, or anonymise it. The use of anonymised data helps us to optimise our customer service.
                </p>
            </section>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>
